<style type="text/css">
  @import "~@/assets/css/common.css";
</style>

<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  // import $ from "jquery" 
  /**
   * 报名信息提交
   */
  export default {
    components: {
      Layout,
      PageHeader,

    },
    data() {
      return {
        title: "证书、成绩单邮寄申请",
        items: [{
            text: "用户首页"
          },
          {
            text: "控制面板",
            active: true
          }
        ],

      };
    },
    methods: {
      // submitInfo() {
      //   $(".msgValInput  h35").css('background-color', "unset");
      // }
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 650px;">
          <div class="card-body">
            <h4 class="card-title border-dash pb-3"><img src="@/assets/images/person/icon/if-paper.png"
                class="mr-2">邮寄方式为中国邮政EMS</h4>
            <div class="outerCotent mt-3 score-list">
              <input type="hidden" class="msgValInput  h35" value="" name="sid">
              <div class="msgContent flexList fs-xs w-75">
                <div class="w-100 msgLabel d-flex " style="height: 100px;">
                  <div class="msgName flexList justify-content-center">选择邮寄项目 </div>
                  <div class="msgVal flexList">
                    <div class="frame-wrap">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="choose1" value="2021年建筑建造职业资格证书">
                        <label class="custom-control-label" style="line-height:19px"
                          for="choose1">2021年建筑建造职业资格证书</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="choose2" value="2020年中医药高级资格证书">
                        <label class="custom-control-label" style="line-height:19px"
                          for="choose2">2020年中医药高级资格证书</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-100 d-flex msgLabel">
                  <div class="msgName flexList justify-content-center ">收件人姓名</div>
                  <div class="msgVal  flexList">
                    <input class="msgValInput  h35  col-sm-4 form-control" value="王小川">
                  </div>
                </div>
                <div class="w-100 d-flex msgLabel" style="height: 120px;">
                  <div class="msgName flexList justify-content-center ">邮寄地址</div>
                  <div class="msgVal selectShow ">
                    <div class="flexList mt-3">
                      <b-dropdown variant="outline-light" class="mr-3">
                        <template v-slot:button-content>
                          四川省
                          <i class="mdi mdi-chevron-down"></i>
                        </template>
                        <b-dropdown-item>四川省</b-dropdown-item>
                        <b-dropdown-item>Another Action</b-dropdown-item>
                        <b-dropdown-item>Something else here</b-dropdown-item>
                      </b-dropdown>
                      <b-dropdown variant="outline-light" class="mr-3">
                        <template v-slot:button-content>
                          成都市
                          <i class="mdi mdi-chevron-down"></i>
                        </template>
                        <b-dropdown-item>四川省</b-dropdown-item>
                        <b-dropdown-item>Another Action</b-dropdown-item>
                        <b-dropdown-item>Something else here</b-dropdown-item>
                      </b-dropdown>
                      <b-dropdown variant="outline-light" class="mr-3">
                        <template v-slot:button-content>
                          金牛区
                          <i class="mdi mdi-chevron-down"></i>
                        </template>
                        <b-dropdown-item>四川省</b-dropdown-item>
                        <b-dropdown-item>Another Action</b-dropdown-item>
                        <b-dropdown-item>Something else here</b-dropdown-item>
                      </b-dropdown>
                    </div>
                     <input class="msgValInput  h35 form-control mt-3 col-sm-4" value="二环路北一段111号" style="padding-left: 5px;">
                  </div>
                </div>
                <div class="w-100 d-flex msgLabel">
                  <div class="msgName flexList justify-content-center ">邮政编码</div>
                  <div class="msgVal  flexList">
                  <input class="msgValInput  h35  col-sm-4 form-control" value="611756">
                  </div>
                </div>
                <div class="w-100 d-flex msgLabel">
                  <div class="msgName flexList justify-content-center ">联系电话</div>
                  <div class="msgVal  flexList">
                   <input class="msgValInput  h35  col-sm-4 form-control" value="138013881388">
                  </div>
                </div>

                <div class="w-100 d-flex msgLabel">
                  <div class="msgName flexList justify-content-center ">邮寄费用</div>
                  <div class="msgVal  flexList">
                   <div class="red-font font-size-16">25.00元</div>
                  </div>
                </div>
                <div class="flexList w-100 msgLabel" style="height: 60px;">
                  <div class="msgName flexList justify-content-center ">操作相关</div>
                  <div class="msgVal  flexList">
                    <button type="button" class=" btn btn-info flexList  mr-4 h30">提交邮寄申请</button>
                    <button type="button" class=" btn btn-secondary flexList mr-2 h30">取消</button>

                  </div>
                </div>

              </div>

            </div>



          </div>
        </div>
      </div>


    </div>

  </Layout>
</template>
